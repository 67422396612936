'use client'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { FC, Fragment, ReactNode } from 'react'
import { queryClient } from '../hooks/query-client'

const AppLayout:FC<{ children: ReactNode }> = ({ children }) => {  
  return (
    <Fragment>
      <QueryClientProvider client={queryClient}>
        {children}
        <ReactQueryDevtools 
          position='bottom'
          initialIsOpen={false} />
      </QueryClientProvider>
    </Fragment>
  )
}

export default AppLayout